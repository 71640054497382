<template>
    <b-card v-if="totalScore" class="flex-auto">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h3>{{ totalScore }} / 10</h3>
                <p>
                    Difficulty :
                    <span v-if="totalScore >= 0 && totalScore < 2.5">easy</span>
                    <span v-if="totalScore >= 2.5 && totalScore < 5">meduim</span>
                    <span v-if="totalScore >= 5 && totalScore < 7.5">hard</span>
                    <span v-if="totalScore >= 7.5 && totalScore < 10">super hard</span>
                </p>
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <div class="progress-img" style="position: relative">
                    <img v-if="totalScore >= 0 && totalScore < 2.5" :src="prgressBarCircle1" />
                    <img v-if="totalScore >= 2.5 && totalScore < 5" :src="prgressBarCircle2" />
                    <img v-if="totalScore >= 5 && totalScore < 7.5" :src="prgressBarCircle3" />
                    <img v-if="totalScore >= 7.5 && totalScore < 10" :src="prgressBarCircle4" />
                </div>
            </div>
        </div>
    </b-card>
</template>
<script>
import {
    BContainer,
    BRow,
    BCol,
    BButton,
    BCard,
    BForm,
    BSpinner,
    BCardText,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormSelect,
    VBTooltip,
} from "bootstrap-vue";
import axios from "@axios";
import appsAxios from "@/libs/appsAxios";
import Ripple from "vue-ripple-directive";
import * as R from "ramda";
import prgressBarCircle1 from "@/assets/images/25.svg";
import prgressBarCircle2 from "@/assets/images/50.svg";
import prgressBarCircle3 from "@/assets/images/75.svg";
import prgressBarCircle4 from "@/assets/images/100.svg";
export default {
    data() {
        return {
            prgressBarCircle1,
            prgressBarCircle2,
            prgressBarCircle3,
            prgressBarCircle4,
            id: null,
            titleScore: null,
            competitorScore: null,
            installsScore: null,
            ratingsScore: null,
            ageScore: null,
            keyword: null,
            apps: [],
            totalScore: null,
            data: null,
            isLoading: false,
        };
    },
    components: {
        BButton,
        BContainer,
        BRow,
        BCol,
        BCard,
        BForm,
        BCardText,
        BSpinner,
        BFormFile,
        BFormInput,
        BFormGroup,
        BFormSelect,
        "b-tooltip": VBTooltip,
    },
    props: {
        data: {
            type: String,
            required: true,
        },
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    mounted() {
        this.runScoreTest(this.data);
    },
    methods: {
        getMatchType(keyword, title) {
            keyword = keyword.toLowerCase();
            title = title.toLowerCase();

            if (title.includes(keyword)) {
                return "exact";
            }
            const matches = keyword.split(" ").map((word) => title.includes(word));
            if (R.all(R.identity, matches)) {
                return "broad";
            }
            if (R.any(R.identity, matches)) {
                return "partial";
            }
            return "none";
        },
        getTtleMatches(keyword) {
            let apps = this.apps;
            const matches = R.pluck("title", apps).map((app) =>
                this.getMatchType(keyword, app)
            );
            const counts = {
                exact: R.filter(R.equals("exact"), matches).length,
                broad: R.filter(R.equals("broad"), matches).length,
                partial: R.filter(R.equals("partial"), matches).length,
                none: R.filter(R.equals("none"), matches).length,
            };

            const score =
                (10 * counts.exact + 5 * counts.broad + 2.5 * counts.partial) /
                apps.length;

            this.titleScore = R.assoc("score", score, counts);
        },
        round(val) {
            return Math.round(val * 100) / 100;
        },
        score(min, max, value) {
            value = Math.min(max, value);
            value = Math.max(min, value);
            return this.round(1 + (9 * (value - min)) / (max - min));
        },
        zScore(max, value) {
            return this.score(0, max, value);
        },
        getInstallsScore() {
            let apps = this.apps;
            const avg = R.sum(R.pluck("minInstalls", apps)) / apps.length;
            const max = 1000000;
            const score = this.zScore(max, avg);
            this.installsScore = { avg: avg, score: score };
        },
        getRating() {
            let apps = this.apps;
            const avg = R.sum(apps.map((app) => app.score || 0)) / apps.length;
            this.ratingsScore = {
                avg: avg,
                score: avg * 2,
            };
        },

        iScore(min, max, value) {
            value = Math.min(max, value);
            value = Math.max(min, value);
            return this.round(1 + (9 * (max - value)) / (max - min));
        },

        // inverted, zero based score
        izScore(max, value) {
            return this.iScore(0, max, value);
        },
        getDaysSince(date) {
            if (typeof date === "string") {
                date = Date.parse(date);
            } else {
                date = date / 1000;
            }
            return Math.floor((Date.now() - date) / 86400000);
        },
        /*
         * Score the average time since last update among the top apps.
         */
        getAge() {
            let apps = this.apps;
            const updated = R.pluck("updated", apps).map(this.getDaysSince);
            const avg = R.sum(updated) / apps.length;
            const max = 500;
            const score = this.izScore(max, avg);

            this.ageScore = {
                avg: avg,
                score,
            };
        },
        async getApps() {
            this.isLoading = true;
            await appsAxios.get(`https://sc1.clicklab.app/api/apps/?q=${this.data}&fullDetail=true`)
            .then(({ data }) => {
                this.apps = data.results;
                this.isLoading = false;
            });
        },

        aggregate(weights, values) {
            const max = 10 * R.sum(weights);
            const min = 1 * R.sum(weights);
            const sum = R.sum(R.zipWith(R.multiply, weights, values));
            return this.score(min, max, sum);
        },
        getScore() {
            const TITLE_W = 4;
            // const COMPETITOR_W = 3;
            const INSTALLS_W = 5;
            const RATING_W = 2;
            const AGE_W = 1;
            this.totalScore = this.aggregate(
                [TITLE_W, INSTALLS_W, RATING_W, AGE_W],
                [
                    this.titleScore.score,
                    this.installsScore.score,
                    this.ratingsScore.score,
                    this.ageScore.score,
                ]
            );
        },
        async runScoreTest(keyword) {
            await this.getApps();
            await Promise.all([
                this.getTtleMatches(keyword),
                this.getInstallsScore(),
                this.getRating(),
                this.getAge(),
            ]);
            this.getScore();
            this.$emit("ready", true);
        },
    },
    watch: {},
};
</script>
<style scoped>
.progress-img {
    position: relative;
}

.progress-img img {
    height: 60px;
    width: 60px;
}

.progress-img .p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
